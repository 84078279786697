import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Footer.css'; // Import CSS file for the footer

const Footer = () => {
    return (
        <footer className="footer">

            <section className="presentation-section" id="presentation">
                <h2>Download Our Presentation</h2> 
                <div className="download-section">
                    <a href="https://drive.google.com/file/d/1WdeqlTZJYAHNFIkm-jKFTMswRMFqJ8Wk/view?usp=sharing" 
                    className="download-link"
                    target="_blank"
                    rel="noopener noreferrer">
                        Pascua Florida Presentation Download
                    </a>
                    <p>(The presentation will be updated regularly)</p>
                </div>
            </section>

            <section className="contact-info">
                <h2>Contact Us</h2>
                <p>
                    <FontAwesomeIcon icon={faEnvelope} className='envelope'/>
                    <a href="mailto:pascuaflorida2022@gmail.com" className="email-link">pascuaflorida2022@gmail.com</a>
                </p>    
            </section>

            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Pascua Florida.</p>
            </div>
        </footer>
    );
};

export default Footer;
