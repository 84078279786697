import React from 'react';
import Footer from './Footer';
import './LandingPage.css';
import Navbar from './NavBar'; // Importing Navbar component
import './ResidentInquiry.css';

function ResidentInquiry() {
    return (
        <div className="resident-inquiry">
            <Navbar /> {/* Navbar at the top */}

            <header className="hero-section">
                <img 
                    src={require('../images/resident-inquiry.jpg')} // Ensure you have this image in your folder
                    alt="Inquiry Header"
                    className="hero-image" 
                />
            </header>

            <div className="hero-text-container">
                <h1>Resident Inquiry</h1>
                <p>
                    If Pascua sounds like somewhere you’d like to live please fill out the following questionnaire. 
                    We are looking for farmers, tradesmen, small business owners, educators, and anyone who believes 
                    that they would be able to make a significant contribution to the founding and development of 
                    Pascua as a strong, spiritually flourishing, and economically viable community.
                </p>
            </div>

            <div className="info-container">
                <h2>Pascua Questionnaire</h2>
                <p>
                    We encourage the questionnaire to be answered in video form but give the option to write. 
                    To submit your videos, upload them to <a href="https://wormhole.app" target="_blank" rel="noopener noreferrer">wormhole.app</a> 
                    {" "} and email your resulting link to <a href="mailto:pascuaflorida2022@gmail.com">pascuaflorida2022@gmail.com</a>.
                </p>

                <ol>
                    <li>Can you briefly tell us a little bit about yourself?</li>
                    <li>What in particular about the vision for Pascua are you personally drawn to the most?</li>
                    <li>Why do you want to be involved with and work for the founding of Pascua?</li>
                    <li>What knowledge, skillsets, experience, interests, professional and social relationships, etc. do you have that can help add value to the project?</li>
                    <li>Can you name a few of your personal strengths and weaknesses?</li>
                    <li>What are some of your favorite hobbies and pastimes?</li>
                    <li>Where did you grow up and where do you currently live?</li>
                    <li>What has been your experience with regard to close-knit family or community life? How would you describe your role in it?</li>
                    <li>What is your attitude towards the traditional Latin Mass?</li>
                    <li>How much land (if any) would you ideally like to own and what would its intended use be?</li>
                    <li>What would you consider your budget for a home to be?</li>
                </ol>
            </div>

           <Footer/>
        </div>
    );
}

export default ResidentInquiry;
