import React from 'react';
import Footer from './Footer';
import './Donate.css'
    
function Donate() {
  return (
    <div className="donate">
      <section className='donate-section' id='donate-sec'>
        <h2>Become A Pascua Patron & Help Us Build Amidst The Ruins</h2>
        <p>
        If you’d like to join us in our effort to build an outpost of Christendom amidst our civilizational ruins please consider making a donation. No gift is too small or too large. If you feel passionately about our mission and would like to do more consider becoming a Patron by signing up for regular recurring donations or ask us about how you can become a longtime Partner by contacting us at pascuaflorida2022@gmail.com

        Pascua Community Land Trust, Inc., is a nonprofit organization that is currently filing for tax exempt status.
        </p>

        <a
        href='https://www.zeffy.com/en-US/donation-form/f320fc86-b84e-4348-977f-0fcc4a55764c'
        target='_blank'
        rel='noopener noreferrer'
        className='donate-button'
        >
          Donate
        </a>
      </section>
      <Footer />
    </div>
  )


}
export default Donate;