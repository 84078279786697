import React from 'react';
import Footer from './Footer';
import './LandingPage.css';
import Navbar from './NavBar'; // Importing Navbar component

function LandingPage() {
  return (
    <div className="landing-page">
      <Navbar /> {/* Navbar at the top */}

      <header className="hero-section">
        <img 
          src={require('../images/Pascua Title.png')} // Ensure you have this image in your folder src={require('../images/hero-image.png')}
          alt="Trees"
          className="hero-image" 
        />
      </header>

      <div className="hero-text-container">
        <h1>Pascua Florida</h1>
        <p>
          Where Traditional Urbanism, Agrarianism, Distributism, The Liberal Arts, Historical Rootedness & The Liturgical Year Meet To Foster An Authentic Catholic Culture
        </p>
      </div>

      <section className="about-section" id="about">
        <h2>About Us</h2>
        <p>
        We are not professional real estate developers, but real people. We form a grassroots network of friends and family with a diversity of skillsets and proficiencies, all sharing a common vision of the good life. We often call ourselves “Traditional” Catholics because we strive to recover the robust Catholicism that inspired the most beautiful works of art, the greatest architecture, music, literature and theology. Ours is the Catholicism of the saints and scholars, of the crusader and the pious peasant. It is integral, alive, palpable and visible. If the New Urbanism and agrarianism are to function as the bones of Pascua then the Catholic Faith and her traditional liturgy are her very soul. In bringing both together we hope to form the living flesh of an authentic Christian culture.
        </p>
      </section>
      <section className="video-section" id="video">
                <div className="video-container">
                    <iframe 
                        width="1000" 
                        height="600" 
                        src="https://www.youtube.com/embed/5GTx4KW2CMg?start=1938" // embed keyword
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    ></iframe>
                </div>
            </section>
      <Footer />
    </div>
  );
}

export default LandingPage;