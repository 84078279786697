import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/cropped-Flag_of_Cross_of_Burgundy_with_the_Seal_of_Florida_alternative_version.svg_.png';
import './NavBar.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(prevState => !prevState); // Use a functional update to toggle state
   
    };

    const closeMenu = () => {
        setIsOpen(false);
        
    } 

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/" onClick={closeMenu}>
                <img src={logo} alt="Pascua Florida Logo" className="logo-image"/>
                </Link>
            </div>

            <div className="navbar-slogan">
                <p>The Reconquista Begins</p>
            </div>
            
            <button className="navbar-toggle" onClick={toggleMenu}>
                {isOpen ? '✖' : '☰'} {/* Change icon based on open/close state */}
            </button>
            <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
                <li><Link to="/" onClick={closeMenu}>Home</Link></li>
                <li><Link to="/our-mission" onClick={closeMenu}>Our Mission</Link></li>
                <li><Link to="/development-plan" onClick={closeMenu}>Development Plan</Link></li>
                <li><Link to="/resident-inquiry" onClick={closeMenu}>Resident Inquiry</Link></li>
                <li><Link to="/donate" onClick={closeMenu}>Donate</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;
