import React from 'react';
import './DevelopmentPlan.css';
import Footer from './Footer';

function DevelopmentPlan() {
    return (
        <div className="development-plan">
            
            <header className="hero-section">
                <img 
                    src={require('../images/mission-shutterstock.jpg')} // Ensure you have this image in the folder
                    alt="Trees"
                    className="hero-image" 
                />
            </header>

            <div className="hero-text-container-dev">
                <h1>Development Plan</h1>
                <p>
                    The loss of community is one of the greatest ills afflicting modern life. However, most of us seem unaware of the role our physical surroundings have been playing in this tragedy for the better part of a century. Instead of walkable environments — where family and friends, church, school, marketplace, and work all blend together within close proximity of one another to form an integrated whole — our current neighborhoods, towns, and cities (indeed, our lives) are segregated by use. So we have commercial zones, retail zones, and residential zones — the last further segregated into single-family and multi-family zones. Beyond this, our residential zones are divided by income level, so that where we live is no longer determined by family, friends, culture, or religion, but by what area we happen to be able to afford to live in. Life has become compartmentalized, and the most prominent feature of our public space is the automobile — with its accompanying vast highways and multi-lane roads that now dominate our entire landscape.
                </p>
                <p>
                    The New Urbanist Movement is an attempt to rectify these ills by taking the wisdom of the past and applying the timeless principles of traditional urban development in our modern day in order to once more create human scale, livable environments where communities can thrive. Pascua will be planned and developed in strict adherence to these principles.
                </p>
            </div>

            <section className="about-section" id="about">
                <h2>Subsidiarity & Incremental Development</h2>
                <p>
                    Although much thought and planning is going into the development of the community, it is not our intention to build to climax conditions from the very start. Favoring a more organic and subsidiary approach, we aim to facilitate an incremental form of development that will allow the settlement to grow and adapt accordingly with time. Making use of these pre-modern modes of development will allow each phase of growth to look and function as a completed whole rather than appearing as a mere slice of an unfinished pie.
                </p>

                <h2>Design & Architecture</h2>
                <p>
                    The community will be composed of several small neighborhoods or hamlets (clusters of about five to ten homes or so) within an agricultural setting. Although closely knit together, they will maintain their own distinct characters and identities. Each, for example, may come to organize its own festivals and markets, adopt a particular patron saint, erect its own shrine or chapel, etc. Particularity and grassroots expressions will always be encouraged. “In essentials, unity; in non-essentials, liberty; in all things, charity.” —St. Augustine
                </p>
                <p>
                    A central church located in what will become the development’s urban center (which will itself constitute its own more populated and more characteristically urban neighborhood) will be encompassed by the various aforementioned peripheral neighborhoods (hamlets) and the agricultural land that together would form the village or town. 
                </p>
                <p>
                    In addition to our Spanish patrimony, Pascua will also seek to honor and celebrate Florida’s Southern heritage and culture. For this reason, the architecture of the buildings will consist of both Spanish and old Florida vernacular styles, e.g. Spanish-style central plaza (plaza mayor) and church, as well as both Spanish and Florida cracker-style homes all built using traditional methods of construction – in many ways resembling Saint Augustine, Florida.
                </p>

                <h2>Where Our Project Currently Stands</h2>
                <p>
                    After extensive research and travel, we have focused our attention on an undisclosed location in northern Florida near the Saint Johns River. In it, there is an existing small historic town center surrounded by ample land and resources to start an agricultural community. The location is centrally located yet relatively remote, allowing for seclusion while also facilitating commerce throughout north and central Florida.
                </p>
                <p>
                    We have targeted a collection of over 400 acres, and we hope to negotiate a purchase. There are commercial and residential properties for sale in the adjacent small town for those friends of the community who are not ready to live in an agricultural environment. We hope to create a symbiotic relationship with the town and surrounding regions. We also remain open to the possibility of incorporating the adjacent small town into the community’s development plan through retrofitting its already existing infrastructure.
                </p>
            </section>
           <Footer/>
        </div>
    );
}

export default DevelopmentPlan;
