import React from 'react';
import Footer from './Footer';
import './OurMission.css';
import Slideshow from './Slideshow';

const OurMission = () => {
    
    const slides = [
        {
            title: 'Our Mission',
            content: `Our society is collapsing around us. Broken homes, the disappearance of community, 
                      the loss of cultural identity, the demise of local freedoms and the outright denial of 
                      God have brought civilization to the brink of total destruction. The remedy for our 
                      societal ills is the restoration of an integral Catholic culture, for which we need to 
                      reform organic communities that are rooted in the timeless traditions of our past.`
        },
        {
            title: 'Our Approach',
            content: `We are acutely aware of the many grave challenges facing our society, however, our goal 
                      is not mere survival or mitigation but human flourishing. Although the need for self-preservation 
                      may inform part of our tactics, the posture of Pascua is one of strategic offense. Taking inspiration 
                      from our Spanish forebears...`
        },
        {
            title: 'Preserving the Flame of Christendom',
            content: `The name Pascua is a homage to our land’s Spanish and Christian roots. Pascua Florida, meaning 
                      flowery Easter, was the name our Catholic forebears gave our state upon first sighting her shores 
                      on Easter Sunday, 1513...`
        },
        {
            title: 'Culture & Economy',
            content: `We view the cultivation, processing, cooking, eating, and trading of food as something that will 
                      be integral to the life of Pascua. We do not, however, wish to be viewed exclusively and strictly 
                      as just an agrarian community...`
        },
        {
            title: 'Affordability & Economic Vitality',
            content: `Providing home and business site affordability and working towards a strong local economy are 
                      integral to Pascua’s mission. We therefore aim to make, and keep, Pascua affordable while protecting 
                      it from becoming an artificial vacation...`
        }
        
        
    ];

    return (
        <div className='our-mission'>
        <div className='slide-showcontainer'>
            <Slideshow slides={slides} />
        </div>
        <Footer />
        </div>
    );
};

export default OurMission;
