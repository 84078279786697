import React, {useState} from "react";
import './Slideshow.css';

const Slideshow = ({ slides }) => {
    const[currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
    };

    return (
        <div className="slideshow">
            <div className="slideshow-content">
                <h2>{slides[currentSlide].title}</h2>
                <p>{slides[currentSlide].content}</p>
            </div>
            <div className="slideshow-controls">
                <button onClick={prevSlide}>&#8249;</button> 
                <button onClick={nextSlide}>&#8250;</button>
            </div>
        </div>
    )
};

export default Slideshow;